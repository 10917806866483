//
// Name:            Home
//
// Description:     Contains all style definitions concerning basic
//					        structure and layout
//
// ========================================================================
// Component: Home Page
// ========================================================================

.hero {
    display:grid;
    position:relative;
    grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: auto;
  	grid-gap: 0;
    padding:0;

    @media only screen and (max-width: 1024px) and (min-width: 1px) {
		display:flex;
        flex-direction:column;
	}
    .video-wrapper {
        display:block;
        position:relative;
        width:100%;
        height: 100%;
        margin:0;
        z-index:3;
        overflow:hidden;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            height:0;
            padding-top:56.25%;
        }
        &:before {
            position:absolute;
            content:'';
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
            z-index:5;
        }
        .video-cnt {
            display:block;
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            margin:0;
            z-index:2;

            video {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit:cover;
                z-index:2;
                transform:translateZ(0);
                will-change: transform;
                backface-visibility: hidden;
            }
        }
    }
    .content {
        position:relative;
        background:$black;
        padding:250px 160px 130px 72px;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:80px 65px;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:50px 30px;
        }
        h1 {
            color:$white;
            margin:0 0 38px 0;
        }
    }
}


.about-section {
    padding-bottom:0;

    h2 {
        margin:0 0 225px 0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            margin:0 0 120px 0;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            margin:0 0 62px 0;
        }
    }
    p {
        font-family: $GibsonBook;
        @include font(48, auto, 56);
        margin:0 0 44px 0;

        @media only screen and (max-width: 767px) and (min-width: 1px) {
            @include font(24, auto, 32);
        }
    }
    .tri-grid {
        background:$yellow;
        margin-left:-120px;
        width:calc(100% + 240px);
        border-top:1px solid $black;
        border-bottom:1px solid $black;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            margin-left:-70px;
            width:calc(100% + 140px);
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            margin-left:-33px;
            width:calc(100% + 66px);
        }
        .col {
            display:flex;
            align-items:center;
            justify-content:center;
            padding:124px 64px;
            border-right:1px solid $black;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:45px 30px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:30px 70px;
                border-bottom:1px solid $black;
            }
            &:first-child:after {
                position:absolute;
                content:'';
                top:-1px;
                right:100%;
                width:50vw;
                height:calc(100% + 2px);
                background:$yellow;
                border-top:1px solid $black;
                border-bottom:1px solid $black;
                z-index:3;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    display:none;
                }
            }
            &:last-child:after {
                position:absolute;
                content:'';
                top:-1px;
                left:100%;
                width:50vw;
                height:calc(100% + 2px);
                background:$yellow;
                border-top:1px solid $black;
                border-bottom:1px solid $black;
                z-index:3;
                
                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    display:none;
                }
            }
            &:last-child {
                border-right:none;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    border-bottom:none;
                }
            }
            p {
                font-family: $GibsonBook;
                @include font(27, auto, 40);
                margin:0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    @include font(18, auto, 28);
                }
            }
        }
    }
    .split-grid {
        margin-left:-120px;
        width:calc(100% + 240px);

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            margin-left:-70px;
            width:calc(100% + 140px);
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            margin-left:-33px;
            width:calc(100% + 66px);
        }
        & + .split-grid {
            padding-top:250px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding-top:0;
            }
        }
        .content {
            padding:64px 120px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:50px 70px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:50px 33px;
            }
            h3 {
                margin:0 0 66px 0;
            }
            h4 {
                margin:0 0 30px 0;
            }
            p, li {
                font-family: $GibsonBook;
                @include font(16, auto, 24);
            }
            ul {
                list-style:none;
            }
            ul li {
                margin:0 0 10px 0;
                position:relative;

                &:before {
                    position:absolute;
                    content:'';
                    top:6px;
                    left:-25px;
                    width:10px;
                    height:10px;
                    border:1px solid $black;
                    border-radius:100%;
                }
            }
            .btn-cnt {
                margin-top:30px;
            }
        }
        .photo {
            width:100%;
            height:100%;
            position:relative;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                height:0;
                padding-top:56.25%;
            }
            img {
                position:relative;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit:cover;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    position:absolute;
                }
            }
        }    
    }
}


.pc-section {
    padding:0 0 160px 0;

    @media only screen and (max-width: 767px) and (min-width: 1px) {
        padding:0 0 50px 0;
    }
    .split-grid {
        position:relative;
        padding:100px 0;
        border-bottom:1px solid $white;
        z-index:3;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:0;
            border-bottom:none;
        }
        &:nth-child(odd) .content {
            padding:0 120px 0 120px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:50px 70px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:50px 33px;
            }
        }
        &:nth-child(even) .content {
            padding:0 120px 0 120px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:50px 70px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:50px 33px;
            }
        }
        &:last-child {
            border-bottom:none;
            padding-bottom:0;
        }
    }
    .wysiwyg {
        padding:50px 120px;
        width:75%;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:50px 70px;
            width:100%;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:50px 33px;
        }
        ol {
            margin:60px 0;

            li {
                font-family: $GibsonBook;
                @include font(27, auto, 40);

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    @include font(18, auto, 27);
                }
            }
        }
        ul {
            list-style:none;
            margin:30px 0;

            li {
                position:relative;

                &:before {
                    position:absolute;
                    content:'';
                    top:5px;
                    left:-25px;
                    width:10px;
                    height:10px;
                    border:1px solid $black;
                    border-radius:100%;
                }
            }
        }
    }
    .content {

        h2 {
            margin:0 0 155px 0;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                margin:0 0 70px 0;
            }
            mark {
                padding:0 10px;
                background:$yellow;
            }
        }
        h3 {
            margin:0 0 60px 0;
        }
        h4 {
            margin:0 0 30px 0;
        }
        p, li {
            font-family: $GibsonBook;
            @include font(16, auto, 24);
        }
        ol {
            margin:60px 0;

            li {
                font-family: $GibsonBook;
                @include font(27, auto, 40);

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    @include font(18, auto, 27);
                }
            }
        }
        ul {
            list-style:none;
            margin:30px 0;
        }
        .large {
            list-style:none;
            padding:0;

            li {
                @include font(27, auto, 40);
                margin:0;
            }
        }
        li {
            margin:0 0 10px 0;
        }
        .btn-cnt {
            margin-top:30px;
        }
    }
    .stats {
        padding:95px 0 0 0;

        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:50px 0 0 0;
        }
        ul {
            padding:0 120px 0 24px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:0 70px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:0 33px;
            }
            li {
                display:flex;
                align-items:center;
                margin:0 0 60px 0;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    flex-direction:column;
                }
                &:last-child {
                    margin:0;
                }
                span {
                    display:flex;
                    align-items: center;
                    flex:1;

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        flex-direction:column;
                        flex:unset;
                    }
                }
                .num {
                    font-family: $GibsonMedium;
                    @include font(92, auto, 56);
                    min-width:240px;
                }
                .line {
                    display:inline-flex;
                    height:2px;
                    background:$blue;
                    margin:0 20px;

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        margin:16px 70px;
                        width:calc(100% - 140px);
                    }
                }
                .desc {
                    position:relative;
                    font-family: $GibsonBook;
                    @include font(16, auto, 24);

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        text-align:center;
                        padding:0 70px;
                    }
                }
            }
        }
    }
    .photo {
        width:100%;
        height:0;
        padding-top:100%;
        position:relative;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            height:0;
            padding-top:56.25%;
        }
        img {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
    .tall-photo {
        width:100%;
        height:100%;
        position:relative;

        img {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
    .watermark {
        position:absolute;
        bottom:10px;
        left:-20px;
        z-index:5;
        font-family: $GibsonMedium;
        @include font(224, auto, 224);
        text-transform:uppercase;
        -webkit-text-stroke: 1px rgba($white, 0.5);
        -webkit-text-fill-color: transparent;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            @include font(124, auto, 124);
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            display:none;
        }
    }
}


.story-section {
    padding:0;

    .story-flex {
        display:flex;
        border-bottom:1px solid $black;

        @media only screen and (max-width: 1023px) and (min-width: 1px) {
            flex-direction:column;
        }
        &:nth-child(odd) {

            .story-item:nth-child(1) {
                background:$white;

                .story {

                    &::-webkit-scrollbar-track {
                        background:$white;
                    }
                    &::-webkit-scrollbar-thumb {
                        background:$blue;
                    }
                }
            }
            .story-item:nth-child(2) {
                background:$blue;

                .person *,
                .story * {
                    color:$white;
                }
                .story {
                    
                    &::-webkit-scrollbar-track {
                        background:$blue;
                    }
                    &::-webkit-scrollbar-thumb {
                        background:$white;
                    }
                }
            }
            .story-item:nth-child(3) {
                background:$yellow;

                .story {
                    
                    &::-webkit-scrollbar-track {
                        background:$yellow;
                    }
                    &::-webkit-scrollbar-thumb {
                        background:$blue;
                    }
                }
            }
        }
        &:nth-child(even) {

            .story-item:nth-child(1) {
                background:$yellow;

                .story {
                    
                    &::-webkit-scrollbar-track {
                        background:$yellow;
                    }
                    &::-webkit-scrollbar-thumb {
                        background:$blue;
                    }
                }
            }
            .story-item:nth-child(2) {
                background:$black;

                .person *,
                .story * {
                    color:$white;
                }
                .story {
                    
                    &::-webkit-scrollbar-track {
                        background:$black;
                    }
                    &::-webkit-scrollbar-thumb {
                        background:$blue;
                    }
                }
            }
            .story-item:nth-child(3) {
                background:$white;

                .story {
                    
                    &::-webkit-scrollbar-track {
                        background:$white;
                    }
                    &::-webkit-scrollbar-thumb {
                        background:$blue;
                    }
                }
            }
        }
        &:last-child {
            border-bottom:none;
        }
    }
    .story-item {
        flex:1;
        height:auto;
        overflow:hidden;
        transition:flex .7s;

        .person {
            display:inline-flex;
            flex-direction:row;
            align-items: center;
            position:relative;
            width:100%;
            visibility: visible;
            opacity:1;
            transition:opacity .4s .7s;

            @media only screen and (max-width: 1023px) and (min-width: 1px) {
                flex-direction:column;
            }
            .cnt {
                display:inline-flex;
                flex-direction:column;
                align-items: center;
                position:relative;
                z-index:5;
                margin:0;
                padding:40px 75px 24px 75px;

                @media only screen and (max-width: 1280px) and (min-width: 1px) {
                    padding:40px 24px 24px 24px;
                }
            }
            p.title {
                font-family: $GibsonMedium;
                @include font(14, auto, 24);
                text-transform:uppercase;
                letter-spacing: 0.15em;
                margin:0;
            }
            h3 {
                font-family: $GibsonRegular;
                @include font(40, auto, 50);
                margin:0 0 20px 0;
            }
            .photo {
                display:block;
                position:relative;
                width:100%;
                max-width:373px;
                min-width:373px;
                border-radius:50%;
                overflow:hidden;
                margin:0 auto 20px auto;
                transition:display 0s .7s;

                &::before {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
                @media only screen and (max-width: 1600px) and (min-width: 1px) {
                    max-width:330px;
                    min-width:330px;
                }
                @media only screen and (max-width: 1440px) and (min-width: 1px) {
                    max-width:270px;
                    min-width:270px;
                }
                img {
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .expand,
            .expand-mobile {
                margin:0 auto;
                padding:0;
                border:none;
                background:transparent;
                font-family: $GibsonMedium;
                @include font(14, auto, 24);
                text-transform:uppercase;
                letter-spacing: 0.15em;
                text-decoration:underline;
                cursor:pointer;

                &:hover {
                    color:#575757;
                }
                &:active {
                    color:#686868;
                }
                &:focus {
                    outline:1px solid $black;
                    outline-offset:2px;
                    transition:outline 0s;
                }
                &:focus:not(:focus-visible) {
                    outline:none;
                }
            }
            @media only screen and (max-width: 1023px) and (min-width: 1px) {
                .expand {
                    display:none;
                }
            }
            .expand-mobile {
                display:none;

                @media only screen and (max-width: 1023px) and (min-width: 1px) {
                    display:block;
                    margin-bottom:40px;
                }
            }
        }
        .story {
            position:absolute;
            visibility: hidden;
            opacity:0;
            right:120px;
            width:calc(100% - 663px);
            transition:opacity 0s 0s;
            padding:0 20px 0 0;
            max-height:310px;
            overflow-x:hidden;
            overflow-y:scroll;
            z-index:1;

            @media only screen and (max-width: 1440px) and (min-width: 1px) {
                width:calc(100% - 563px);
            }
            @media only screen and (max-width: 1280px) and (min-width: 1px) {
                width:calc(100% - 383px);
                right:40px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 1px) {
                display:none;
                position:relative;
                right:unset;
                width:100%;
                padding:0 24px;
                overflow-y:auto;
                overflow-x:auto;
                opacity:1;
                visibility: visible;
                max-height:100%;
            }
            &::-webkit-scrollbar {
                width: 10px;
            }
        }
        &.collapse {
            flex:0;

            @media only screen and (max-width: 1023px) and (min-width: 1px) {
                flex:1;
            }
            .person {
                visibility: hidden;
                opacity:0;
                transition:opacity 0s 0s;

                .photo {
                    display:none;
                    transition:display 0s 0s;
                }
            }
        }
        &.open {

            .story {
                visibility: visible;
                opacity:1;
                transition:opacity .4s .7s;

                @media only screen and (max-width: 1023px) and (min-width: 1px) {
                    display:block;
                    padding-bottom:40px;
                }
            }
        }
    }
}


.stats-section {
    padding:0;

    .swiper-slide {

        // @media only screen and (max-width: 1024px) and (min-width: 1px) {
        //     .photo {
        //         position:relative;
        //         width:100%;
        //         height:340px;

        //         img {
        //             position:absolute;
        //             top:0;
        //             left:0;
        //             width:100%;
        //             height:100%;
        //             object-fit:cover;
        //         }
        //     }
        // }
        .head {
            flex-basis:50%;
            max-width:50%;
            margin:0 0 50px 0;
            padding:50px 120px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                flex-basis:100%;
                max-width:100%;
                margin:0 0 50px 0;
                padding:44px 120px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:44px 33px;
            }
            h2 {
                margin:0 0 95px 0;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    margin:0 0 30px 0;
                }
            }
            p {
                font-family: $GibsonBook;
                @include font(27, auto, 40);

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    @include font(18, auto, 27);
                }
            }
        }
        .body {
            display:grid;
            position:relative;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: auto;
            grid-gap: 32px;
            padding-bottom:100px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                display:flex;
                flex-direction:column;
            }
            .photo {
                position:relative;
                width:100%;
                height:0;
                padding-top:56.25%;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    display:none;
                }
                img {
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            ul {
                list-style:none;
                padding:0 100px 0 0;
                margin:0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    padding:0 120px;
                }
                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    padding:0 33px;
                }
                li {
                    display:flex;
                    align-items:center;
                    position:relative;
                    margin:0 0 60px 0;

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        flex-direction:column;
                        align-items: center;
                        justify-content: center;
                    }
                    &:last-child {
                        margin:0;
                    }
                    span {
                        display:flex;
                        align-items:center;
                        flex:1;

                        @media only screen and (max-width: 767px) and (min-width: 1px) {
                            flex-direction:column;
                            align-items: center;
                            justify-content: center;
                            flex:unset;
                        }
                    }
                    img {
                        display:inline-flex;
                        width:62px;
                        min-width:62px;
                        height:62px;
                        margin:0 25px 0 0;

                        @media only screen and (max-width: 767px) and (min-width: 1px) {
                            margin:0 auto 24px auto;
                        }
                    }
                    .num {
                        font-family: $GibsonMedium;
                        @include font(92, auto, 56);

                        @media only screen and (max-width: 767px) and (min-width: 1px) {
                            @include font(74, auto, 56);
                        }
                    }
                    .line {
                        display:inline-flex;
                        height:2px;
                        background:$yellow;
                        margin:0 20px;

                        @media only screen and (max-width: 767px) and (min-width: 1px) {
                            margin:16px auto 16px auto;
                            width:100%;
                        }
                    }
                    .desc {
                        position:relative;
                        font-family: $GibsonBook;
                        @include font(16, auto, 24);
                    }
                }
            }
        }
    }
    .swiper-controls {
        display:grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: auto;
        grid-gap: 32px;
        position:absolute;
        top:50px;
        right:120px;
        z-index:5;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            display:flex;
            justify-content: flex-end;
            grid-gap:0;
            top:50%;
            right:20px;
            transform:translate(0, -50%);
        }
        .swiper-prev {
            display:flex;
            align-items:center;
            font-family: $GibsonMedium;
            @include font(14, auto, 24);
            color:$white;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            text-decoration:underline;
            border:none;
            background:transparent;
            margin:0 auto 0 0;
            cursor:pointer;
            transition:$transition;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                display:none;
            }
            span {
                display:inline-flex;
                position:relative;
                width:74px;
                height:74px;
                border-radius:100%;
                background:$yellow;
                margin:0 32px 0 0;
                transition:$transition;

                &:after {
                    position:absolute;
                    content:'';
                    top:50%;
                    left:50%;
                    width:32px;
                    height:32px;
                    background:url("../images/swiper-arrow.svg") 50% no-repeat;
                    background-size:100%;
                    transform:translate(-50%, -50%);
                }
            }
            &:hover span {
                background:$blue;
            }
            &:focus {
                outline:2px solid $yellow;
                outline-offset:2px;
                transition:outline 0s;
            }
            &:focus:not(:focus-visible) {
                outline:none;
            }
        }
        .swiper-next {
            display:flex;
            align-items:center;
            font-family: $GibsonMedium;
            @include font(14, auto, 24);
            color:$white;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            text-decoration:underline;
            border:none;
            background:transparent;
            margin:0 0 0 auto;
            cursor:pointer;
            transition:$transition;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                text-indent:-9999px;
            }
            span {
                display:inline-flex;
                position:relative;
                width:74px;
                height:74px;
                border-radius:100%;
                background:$yellow;
                margin:0 0 0 32px;
                transition:$transition;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    width:44px;
                    height:44px;
                }
                &:after {
                    position:absolute;
                    content:'';
                    top:50%;
                    left:50%;
                    width:32px;
                    height:32px;
                    background:url("../images/swiper-arrow.svg") 50% no-repeat;
                    background-size:100%;
                    transform:translate(-50%, -50%) rotate(180deg);

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        width:24px;
                        height:24px;
                    }
                }
            }
            &:hover span {
                background:$blue;
            }
            &:focus {
                outline:2px solid $yellow;
                outline-offset:2px;
                transition:outline 0s;
            }
            &:focus:not(:focus-visible) {
                outline:none;
            }
        }
    }
}


.donation-section {
    padding:0;

    .split-grid {
        position:relative;
        padding:0 0 164px 0;
        border-bottom:1px solid $black;
        z-index:3;

        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:0;
        }
        &:last-child {
            padding:0 0 74px 0;
            border-bottom:none;
        }
    }
    .content {
        padding:0 24px 64px 120px;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:0 70px 64px 70px;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:0 33px 64px 33px;
        }
        h2 {
            margin:95px 0 74px 0;

            @media only screen and (max-width: 767px) and (min-width: 1px) {
                margin:24px 0;
            }
            mark {
                padding:0 10px;
                background:$yellow;
            }
        }
        h3 {
            margin:0 0 46px 0;
        }
        p {
            font-family: $GibsonBook;
            @include font(48, auto, 56);

            @media only screen and (max-width: 767px) and (min-width: 1px) {
                @include font(24, auto, 32);
            }
        }
        li {
            font-family: $GibsonBook;
            @include font(27, auto, 40);

            @media only screen and (max-width: 767px) and (min-width: 1px) {
                @include font(18, auto, 27);
            }
        }
    }
    .stats {
        padding:95px 0 0 0;

        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:50px 0 0 0;
        }
        ul {
            padding:0 120px 0 24px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:0 70px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                padding:0 33px;
            }
            li {
                display:flex;
                align-items:center;
                margin:0 0 60px 0;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    flex-direction:column;
                }
                &:last-child {
                    margin:0;
                }
                span {
                    display:flex;
                    align-items: center;
                    flex:1;

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        flex-direction:column;
                        flex:unset;
                    }
                }
                .num {
                    font-family: $GibsonMedium;
                    @include font(92, auto, 56);
                    min-width:240px;
                }
                .line {
                    display:inline-flex;
                    height:2px;
                    background:$blue;
                    margin:0 20px;

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        margin:16px 70px;
                        width:calc(100% - 140px);
                    }
                }
                .desc {
                    position:relative;
                    font-family: $GibsonBook;
                    @include font(16, auto, 24);

                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        text-align:center;
                        padding:0 70px;
                    }
                }
            }
        }
    }
    .photo {
        width:100%;
        height:0;
        padding-top:100%;
        position:relative;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            height:0;
            padding-top:56.25%;
        }
        img {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
    .watermark {
        position:absolute;
        bottom:55px;
        right:-20px;
        z-index:5;
        font-family: $GibsonMedium;
        @include font(164, auto, 164);
        text-transform:uppercase;
        -webkit-text-stroke: 1px rgba($black, 0.5);
        -webkit-text-fill-color: transparent;

        @media only screen and (max-width: 767px) and (min-width: 1px) {
            display:none;
        }
    }
}


.give-section {
    padding:0;

    .head {
        padding:50px 120px;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:50px 70px;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:50px 33px;
        }
        h2 {
            margin:0 0 100px 0;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                margin:130px 0 30px 0;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                margin:30px 0 0 0;
            }
        }
    }
    .grid-item {
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: space-between;
        border-top:1px solid $black;
        border-bottom:1px solid $black;
        border-right:1px solid $black;
        padding:55px 60px;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            border:1px solid $black;
            margin:-1px;
        }
        &:first-child {
            border-left:1px solid $black;

            // @media only screen and (max-width: 1024px) and (min-width: 1px) {
            //     border-left:none;
            // }
        }
        img {
            flex-grow:1;
            margin:0 auto 24px auto;
            max-height:100px;
        }
        p {
            font-family: $GibsonBook;
            @include font(27, auto, 30);
            margin:0 0 57px 0;
            text-align:center;
        }
        a:focus {
            outline:2px solid $blue;
            outline-offset:2px;
            transition:outline 0s;
        }
        a:focus:not(:focus-visible) {
            outline:none;
        }
    }
}


.news-section {

    h2 {
        margin:0 0 56px 0;
    }
    @media only screen and (max-width: 1024px) and (min-width: 1px) {
        .row {
            flex-direction:column;
        }
        .col-4 {
            flex-basis:100%;
            max-width:100%;
            width:100%;
            margin-bottom:30px;

            &:last-child {
                margin-bottom:0;
            }
        }
    }
}


.newsletter-section {

    @media only screen and (max-width: 1024px) and (min-width: 1px) {
        .row {
            flex-direction:column;
        }
        .col-6 {
            flex-basis:100%;
            max-width:100%;
            width:100%;
        }
    }
    h3 {
        margin:0 0 30px 0;
    }
    p {
        font-family: $GibsonBook;
        @include font(27, auto, 40);
        margin:0;
    }
    form {
        display:flex;
        align-items:center;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            width:100%;
            margin-top:24px;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
			flex-direction:column;
		}
        label {
            border: 0;
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        input[type="text"] {
            width:100%;
            background:transparent;
            border:1px solid $white;
            color:$white;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            margin:0 17px 0 0;
            padding:20px 17px;
            border-radius:0;

            @media only screen and (max-width: 767px) and (min-width: 1px) {
                margin:0;
            }
            &:focus {
                outline:2px solid $white;
                outline-offset:2px;
                transition:outline 0s;
            }
            &:focus:not(:focus-visible) {
                outline:none;
            }
            &:-ms-input-placeholder {
                opacity: 1;
                color:$white;
                letter-spacing: 0.15em;
                text-transform: uppercase;
            }
            &::-moz-placeholder {
                opacity: 1;
                color:$white;
                letter-spacing: 0.15em;
                text-transform: uppercase;
            }
            &::-webkit-input-placeholder {
                opacity: 1;
                color:$white;
                letter-spacing: 0.15em;
                text-transform: uppercase;
            }
        }
        input[type="submit"] {
            display:inline-flex;
            justify-content:center;
            padding:15px 60px;
            font-family: $OpenSansBold;
            @include font(14, auto, 24);
            letter-spacing: 0.15em;
            text-transform: uppercase;
            background:$yellow;
            color:$black;
            border:2px solid $yellow;
            cursor:pointer;

            &:hover {
                background:$white;
            }
            &:focus {
                outline:2px solid $black;
                outline-offset:2px;
                transition:outline 0s;
            }
            &:focus:not(:focus-visible) {
                outline:none;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                width:100%;
                margin-top:24px;
            }
        }
    }
}
