//
// Name:            Header
//
// Description:     Contains all header styles
//
// ========================================================================
// Component: Header
// ========================================================================

header {
	position:absolute;
    top:0;
    left:0;
    width:100%;
    padding:56px 44px;
    z-index:5;

    @media only screen and (max-width: 1024px) and (min-width: 1px) {
        padding:33px 44px;
    }
    @media only screen and (max-width: 767px) and (min-width: 1px) {
        padding:16px;
    }
    .max-width {
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        display:inline-flex;
        position:relative;
        width:251px;
        height:73px;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            width:157px;
            height:45px;
        }
        span {
            position:absolute;
            top:0;
            left:0;
            text-indent:-9999px;
        }
        &:focus {
            outline:2px solid $yellow;
            outline-offset:2px;
            transition:outline 0s;
        }
        &:focus:not(:focus-visible) {
            outline:none;
        }
    }
    .hamburger {
        display:inline-flex;
        align-items:center;
        justify-content: center;
        width:92px;
        height:92px;
        border-radius:100%;
        background:$blue;
        border:none;
        padding:0;
        z-index:91;
        cursor:pointer;
        transition:.4s;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            width:67px;
            height:67px;
        }
        span {
            font-family: $GibsonBook;
            @include font(18, auto, 27);
            color:$white;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                @include font(14, auto, 22);
            }
        }
        &:hover {
            background:$white;

            span {
                color:$black;
            }
        }
        &.is-active {
            background:$white;

            span {
                color:$black;
            }
            &:hover {
                background:$black;

                span {
                    color:$white;
                }
            }
        }
    }
}
