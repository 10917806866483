//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//
// ========================================================================
// Component
// ========================================================================

html {
	font-size: $base-font-size + px;
	line-height: rem(24);
	font-family: $GibsonRegular;
	-webkit-font-smoothing: antialiased;
}

body {
	overflow-x: hidden;

	&.fixed {
		overflow:hidden;
	}
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}

// animation stopper
.resize-animation-stopper * {
	animation: none !important;
	transition: none !important;
}

body.resize-animation-stopper:before {
	animation: none !important;
	transition: none !important;
}

p > a {
	// color: $brand-primary;
	text-decoration: underline;

	&:hover,
	&:focus {
		// color: $secondary-orange;
		text-decoration: underline;
	}
}

video::-webkit-media-controls {
	display: none !important;
}

// Screen reader text
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// Heading styles
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $GibsonRegular;
	font-weight: normal;
}

h1 {
	@include font(115, auto, 96);

	@media only screen and (max-width: 1280px) and (min-width: 1px) {
		@include font(74, auto, 76);
	}
	@media only screen and (max-width: 767px) and (min-width: 1px) {
		@include font(46, auto, 54);
	}
}

h2 {
	@include font(97, auto, 90);

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		@include font(70, auto, 72);
	}
	@media only screen and (max-width: 767px) and (min-width: 1px) {
		@include font(40, auto, 44);
	}
}

h3 {
	@include font(48, auto, 56);

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		@include font(37, auto, 45);
	}
	@media only screen and (max-width: 767px) and (min-width: 1px) {
		@include font(20, auto, 28);
	}
}

h4 {
	font-family: $GibsonBook;
	@include font(35, auto, 43);

	@media only screen and (max-width: 767px) and (min-width: 1px) {
		@include font(18, auto, 27);
	}
}

// Global text styles
blockquote {
	font-family: $GibsonMedium;
	@include font(24, auto, 35);
}

// skip link
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal;
	outline:none;
	box-shadow:none;
	background:$blue;
	color:$white;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
	padding:12px 60px;
    font-family: $OpenSansBold;
    @include font(14, auto, 24);
    letter-spacing: 0.15em;
    text-transform: uppercase;
    border:2px solid transparent;
    transition:$transition;
	background:$blue;
	color:$white;
	border:2px solid $blue;
	cursor:pointer;

	&:hover {
		background:$yellow;
		color:$black;
		border:2px solid $yellow;
	}
	&:focus {
		clip: auto;
		clip-path: none;
		display: block;
		height: auto;
		top: 5px;
		left: 5px;
		text-decoration: none;
		width: auto;
		z-index: 100000;
	}
}