//
// Name:            Post
//
// Description:     Contains all post styles
//
// ========================================================================
// Component: Post
// ========================================================================

.post {
	display:inline-flex;
	flex-direction:column;
	background:#fff;
	box-shadow: 0px 4px 8px rgba(100, 116, 139, 0.2);
	height:100%;

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		display:grid;
		grid-template-columns: 245px 1fr;
		grid-template-rows: auto;
		grid-gap: 0;
    }
	@media only screen and (max-width: 767px) and (min-width: 1px) {
		display:inline-flex;
		flex-direction:column;	
	}
	.thumb {
		display:inline-flex;
		position:relative;
		width:100%;
		height:160px;

		@media only screen and (max-width: 1024px) and (min-width: 1px) {
			width:245px;
			height:100%;
		}
		@media only screen and (max-width: 767px) and (min-width: 1px) {
			width:100%;
			height:160px;
		}
		img {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			object-fit:cover;
			z-index:1;
		}
		// .white-quotes {
		// 	width:100px;
		// 	height:70px;
		// 	top:12px;
		// 	left:12px;
		// 	background:url('../images/white-quotes.png') 50% no-repeat;
		// 	background-size:100%;
		// 	z-index:2;
		// }
		// .blue-quotes {
		// 	width:46px;
		// 	height:30px;
		// 	top:unset;
		// 	left:unset;
		// 	bottom:12px;
		// 	right:12px;
		// 	background:url('../images/blue-quotes.png') 50% no-repeat;
		// 	background-size:100%;
		// 	z-index:2;
		// }
		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:5;
			text-indent:-9999px;

			&:focus {
                outline:2px solid $blue;
                outline-offset:2px;
                transition:outline 0s;
            }
            &:focus:not(:focus-visible) {
                outline:none;
            }
			// &.video {
			// 	background:rgba(71, 85, 105, 0.6);
				
			// 	&:before {
			// 		position:absolute;
			// 		content:'';
			// 		width:103px;
			// 		height:103px;
			// 		top:50%;
			// 		left:50%;
			// 		border:3px solid $white;
			// 		border-radius:100%;
			// 		background:url('../images/icon-play.svg') 50% no-repeat;
			// 		transform:translate(-50%, -50%);
			// 	}
			// }
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		padding:24px;
		text-align:left;
		height:100%;

		.grow {
			flex-grow:1;
		}
		h3 {
			font-family: $GibsonSemibold;
			@include font(20, auto, 30);
			color:$black;
			margin:0 0 8px 0;

			a {
				font-family: $GibsonSemibold;
                @include font(20, auto, 30);
				color:$black;
				text-transform:none;
				text-decoration:none;

				&:hover {
					text-decoration:underline;
				}
				&:focus {
					outline:2px solid $blue;
					outline-offset:2px;
					transition:outline 0s;
				}
				&:focus:not(:focus-visible) {
					outline:none;
				}
			}
		}
		.date {
			font-family: $GibsonSemibold;
			@include font(16, auto, 24);
			color:#6C7787;
			margin:0 0 16px 0;
		}
		.podcast {
			font-family: $GibsonSemibold;
			@include font(16, auto, 24);
			color:$black;
			margin:0 0 24px 0;
		}
		.excerpt {
			font-family: $GibsonSemibold;
			@include font(16, auto, 24);
			margin:0 0 18px 0;

			p {
				font-family: $GibsonSemibold;
                @include font(16, auto, 24);
				margin:0;
			}
		}
	}
	.btn {
		margin:24px 0 0 0;

		&:focus {
			outline:2px solid $blue;
			outline-offset:2px;
			transition:outline 0s;
		}
		&:focus:not(:focus-visible) {
			outline:none;
		}
	}
	// &.audio {
	// 	background: #E3E1DB;

	// 	.thumb {
	// 		width:calc(100% - 80px);
	// 		height:340px;
	// 		background:$blue-gradient;
	// 		margin:0 40px 40px 40px;

	// 		@media only screen and (max-width: 767px) and (min-width: 1px) {
	// 			margin:0 24px 24px 24px;
	// 			width:calc(100% - 48px);
	// 		}
	// 		img {
	// 			width:100px;
	// 			height:100px;
	// 			top:50%;
	// 			left:50%;
	// 			transform:translate(-50%, -50%);
	// 		}
	// 	}
	// 	.content {
	// 		padding:40px;
	// 		margin-bottom:0;

	// 		@media only screen and (max-width: 767px) and (min-width: 1px) {
	// 			padding:24px;
	// 		}
	// 		h3 {
	// 			font-family: $BioRhyme-Bold;
	// 			@include ccc-font(24, 36);
	// 			color:$black;
	// 			margin:0 0 8px 0;
	
	// 			a {
	// 				font-family: $BioRhyme-Bold;
	// 				@include ccc-font(24, 36);
	// 				color:$black;
	// 				text-transform:none;
	// 				text-decoration:none;
	
	// 				&:hover {
	// 					text-decoration:underline;
	// 				}
	// 			}
	// 		}
	// 		.excerpt {
	// 			margin:0;
	// 		}
	// 	}
	// 	.podcast {
	// 		font-family: $Gibson-SemiBold;
	// 		@include ccc-font(16, 24);
	// 		color:$black;
	// 		margin:0 0 24px 0;
	// 	}
	// 	.btn {
	// 		margin:0 40px 40px 40px;

	// 		@media only screen and (max-width: 767px) and (min-width: 1px) {
	// 			margin:0 24px 24px 24px;
	// 		}
	// 	}
	// 	.btn img {
	// 		margin-left:16px;
	// 	}
	// }
}

.tag {
	display:inline-block;
	font-family: $OpenSansSemiBold;
	@include font(14, auto, 20);
	border-radius:4px;
	margin:0 8px 8px 0;
	padding:3px 8px;
	color: #305aa3;
	background: #EFF6FF;
	border: 1px solid #305aa3;
	white-space:nowrap;

	&:hover {
        background:#305aa3;
        color:$white;
    }
	&:focus {
		outline:2px solid $black;
		outline-offset:2px;
		transition:outline 0s;
	}
	&:focus:not(:focus-visible) {
		outline:none;
	}
	&.hw-tag {
		color: #b25533;
		background: rgba(178, 85, 51, 0.1);
		border: 1px solid #b25533;

		&:hover {
			background:#b25533;
			color:$white;
		}
	}
	&.kk-tag {
		color: #972d39;
		background: rgba(151, 45, 57, 0.1);
		border: 1px solid #972d39;

		&:hover {
			background:#972d39;
			color:$white;
		}
	}
	&.rjd-tag {
		color: #966531;
		background: rgba(150, 101, 49, 0.1);
		border: 1px solid #966531;

		&:hover {
			background:#966531;
			color:$white;
		}
	}
	&.ww-tag {
		color: #31737d;
		background: rgba(49, 115, 125, 0.1);
		border: 1px solid #31737d;

		&:hover {
			background:#31737d;
			color:$white;
		}
	}
	&.hst-tag {
		color: #214636;
		background: rgba(33, 70, 54, 0.1);
		border: 1px solid #214636;

		&:hover {
			background:#214636;
			color:$white;
		}
	}
	&.oh-tag {
		color: #52327d;
		background: rgba(82, 50, 125, 0.1);
		border: 1px solid #52327d;

		&:hover {
			background:#52327d;
			color:$white;
		}
	}
	&.mx-tag {
		color: #d03c33;
		background: rgba(208, 60, 51, 0.1);
		border: 1px solid #d03c33;

		&:hover {
			background:#d03c33;
			color:$white;
		}
	}
}