//
// Name:            Page
//
// Description:     Contains all style definitions concerning basic
//					        structure and layout
//
// ========================================================================
// Component: Flex Page
// ========================================================================

.banner {
    position:relative;
    width:100%;
    height:620px;
    padding:0;

    @media only screen and (max-width: 1024px) and (min-width: 1px) {
        height:420px;
    }
    .cnt {
        display:flex;
        align-items:flex-end;
        padding:60px 44px;
        position:absolute;
        top:0;
        left:50%;
        width:100%;
        height:100%;
        z-index:4;
        transform:translate(-50%, 0);

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:50px 33px;
        }
    }
    h1 {
        color:$white;
    }
    .background {
        position:relative;
        width:100%;
        height:620px;
        z-index:3;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            height:420px;
        }
        img {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            object-fit:cover;
            z-index:1;
        }
        &:before {
            position:absolute;
            content:'';
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.52)), url(pexels-chait-goli-2088233.jpg);
            z-index:5;
        }
    }
}


.flex-page .about-section h2 {
    margin:0 0 60px 0;
}


.testimonial-section {

    .max-width > h2 {
        padding-right:250px;
        margin:0 0 70px 0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding-right:0;
        }
    }
    .max-width > p {
        padding-right:250px;
        font-family: $GibsonBook;
        @include font(27, auto, 40);
        margin:0 0 120px 0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            @include font(18, auto, 27);
            padding-right:0;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            margin:0 0 30px 0;
        }
    }
    .testimonial-box {
        display:flex;
        padding:50px 75px;
        margin:0 0 60px 0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            flex-direction:column;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:33px;
        }
        &:last-child {
            margin:0;
        }
        &.yellow-bg {
            background:$yellow;
        }
        &.blue-bg {
            background:$blue;

            * {
                color:$white;
            }
            .content .name:before {
                background:$white;
            }
        }
        .photo {
            position:relative;
            width:330px;
            min-width:330px;
            height:330px;
            margin-right:120px;
            border-radius:100%;
            overflow:hidden;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                margin:0 auto 30px auto;
                width: 183px;
                min-width:183px;
                height: 183px;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                margin:0 auto 30px auto;
                width: 127px;
                min-width:127px;
                height: 127px;
            }
            img {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit:cover;
            }
        }
        .content {
            padding:0 200px;
            
            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                padding:0;
            }
            blockquote {
                font-family: $GibsonMedium;
                @include font(24, auto, 35);
                margin:0 0 25px 0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    @include font(21, auto, 33);
                }
                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    @include font(16, auto, 23);
                }
            }
            .name {
                position:relative;
                font-family: $GibsonBook;
                @include font(27, auto, 40);
                padding-left:56px;
                margin:0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    @include font(18, auto, 27);
                }
                &:before {
                    position:absolute;
                    content:'';
                    top:50%;
                    left:0;
                    width:36px;
                    height:1px;
                    background:$black;
                    transform:translate(0, -50%);
                }
            }
            .title {
                font-family: $GibsonMedium;
                @include font(19, auto, 35);
                text-transform:uppercase;
                padding-left:56px;
                margin:0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    @include font(14, auto, 22);
                }
            }
        }
        .photo + .content {
            padding:0;
        }
    }
}


.flex-block-form {
    padding:0;

    .row {
        margin:0;
    }
    .col-6,
    .col-12 {
        padding:0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            flex-basis:100%;
            max-width:100%;
        }
    }
    .form-cnt {
        padding:88px 120px;
        height:100%;
        text-align:left;
        background:$blue;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            padding:80px 64px;
        }
        @media only screen and (max-width: 767px) and (min-width: 1px) {
            padding:64px 32px;
        }
        h2 {
            margin:0 0 20px 0;
        }
        h2,
        p {
            color:$white;
        }
        p {
            @include font(16, auto, 24);
            margin:0 0 60px 0;
        }
    }
    .photo {
        height:100%;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            display:none;
        }
        img {
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
}


.people-section {

    h2 {
        margin:0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            margin:0 0 30px 0;
        }
    }
    .tri-grid {
        padding:0 50px;
        margin:90px 0 50px 0;
        border-bottom:1px solid $white;
        gap:132px;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            display:flex;
            flex-direction:column;
            padding:0;
            margin:0;
            gap:0;
            border:none;
        }
        &:last-child {
            border-bottom:none;
        }
        .people-item {
            margin-bottom:50px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                display:flex;
                flex-direction:row;
            }
            @media only screen and (max-width: 767px) and (min-width: 1px) {
                flex-direction:column;
            }
            .photo {
                position:relative;
                width:100%;
                height:0;
                padding-top:100%;
                margin:0 auto 40px auto;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    max-width:278px;
                    height:278px;
                    padding:0;
                    margin:0 30px 0 0;
                }
                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    margin:0 auto 40px auto;
                }
                img {
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .content {

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    width:100%;
                    max-width:278px;
                    margin:0 auto;
                }
            }
            h3 {
                margin:0;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    @include font(37, auto, 45);
                }
            }
            .tagline {
                display:block;
                @include font(27, auto, 40);
                margin:0;
            }
            .title {
                display:block;
                @include font(16, auto, 24);
                margin:0;
            }
            .email {
                display:block;
                @include font(16, auto, 24);
                margin:0 0 20px 0;
                text-decoration:underline;
            }
            a {
                display:block;
                @include font(16, auto, 24);
                margin:0;
                text-decoration:underline;

                &:hover {
                    text-decoration:none;
                }
                &:focus {
                    outline:2px solid $white;
                    outline-offset:2px;
                    transition:outline 0s;
                }
                &:focus:not(:focus-visible) {
                    outline:none;
                }
            }
        }
    }
}


.video-section {

    @media only screen and (max-width: 1024px) and (min-width: 1px) {
        padding-bottom:0;

        .row {
            flex-direction:column;

            .col-6 {
                flex-basis:100%;
                max-width:100%;
                width:100%;
            }
            h2 {
                margin:0 0 30px 0;
                padding-right:160px;
            }
        }
    }
    p {
        @include font(27, auto, 40);
        text-align:right;

        @media only screen and (max-width: 767px) and (min-width: 1px) {
            @include font(23, auto, 32);
        }
    }
    .video-cnt {
        position:relative;
        width:100%;
        height:600px;
        margin:20px 0 0 0;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            margin-left:-70px;
            width:calc(100% + 140px);
            height:420px;
        }
        &:before {
            position:absolute;
            content:'';
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
            z-index:5;
        }
        button {
            position:absolute;
            top:50%;
            left:50%;
            width:80px;
            height:80px;
            transform:translate(-50%, -50%);
            z-index:6;
            background:url("../images/play.svg") $blue 50% no-repeat;
            border:none;
            border-radius:100%;
            padding:0;
            text-indent:-9999px;
            cursor:pointer;

            &:hover {
                background:url("../images/play.svg") $black 50% no-repeat;
            }
            &:focus {
                outline:2px solid $black;
                outline-offset:2px;
                transition:outline 0s;
            }
            &:focus:not(:focus-visible) {
                outline:none;
            }
        }
        .photo {
            position:relative;
            width:100%;
            height:100%;
            z-index:2;

            img {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit:cover;
            }
        }
    }
}


.partner-section {

    h2 {
        margin:0 0 70px 0;
        width:50%;
    }
    .logo-grid {
        display:flex;
        align-items:center;
        justify-content: center;
        flex-wrap:wrap;

        img {
            margin:30px 35px;
        }
    }
}


.grid-section {

    h2 {
        margin:0 0 60px 0;
    }
    .quad-grid {
        gap:24px;
    }
    .grid-item {
        margin:0 auto 30px auto;

        .photo {
            position:relative;
            width:100%;
            height:0;
            padding-top:56.25%;
            margin:0 0 16px 0;

            img {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit:cover;
            }
        }
        .title {
            font-family:$GibsonMedium;
            @include font(19, auto, 35);
            text-transform:uppercase;
            margin:0 0 4px 0;
        }
        p {
            @include font(16, auto, 24);
            margin:0;

            a {
                @include font(16, auto, 24);
                color:$blue;
                text-decoration:underline;

                &:hover {
                    text-decoration:none;
                }
                &:focus {
                    outline:2px solid $blue;
                    outline-offset:2px;
                    transition:outline 0s;
                }
                &:focus:not(:focus-visible) {
                    outline:none;
                }
            }
        }
    }
    .btn-cnt {
        display:flex;
        justify-content: center;
        align-items:center;
        margin:50px 0 0 0;

        @media only screen and (max-width: 467px) and (min-width: 1px) {
            flex-direction:column;
        }
        a {
            margin:0 10px;

            @media only screen and (max-width: 467px) and (min-width: 1px) {
                margin:0 0 20px 0;
                width:100%;
            }
        }
    }
}
