//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//
// ========================================================================
// Component: Navigation
// ========================================================================

nav.primary {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:$blue;
    padding:220px 60px 50px 60px;
    transform:translate3d(100%, 0, 0);
    z-index:9;
    transition:.7s;

    &.open {
        transform:translate3d(0, 0, 0);
    }
    ul {
        list-style:none;
        padding:0;
        margin:0;

        li {
            margin:0 0 24px 0;

            a {
                font-family:$GibsonRegular;
                @include font(48, auto, 56);
                color:$white;

                &:hover {
                    color:$yellow;
                }
                &:focus {
                    outline:2px solid $white;
                    outline-offset:2px;
                    transition:outline 0s;
                }
                &:focus:not(:focus-visible) {
                    outline:none;
                }
            }
        }
    }
}