//
// Name:            Fonts
//
// Description:     Contains all fonts
//
// ========================================================================
// Component: Fonts
// ========================================================================

@font-face {
    font-family: 'Gibson Regular';
    src: url('../fonts/Gibson-Regular.woff2') format('woff2'),
        url('../fonts/Gibson-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gibson Book';
    src: url('../fonts/Gibson-Book.woff2') format('woff2'),
        url('../fonts/Gibson-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gibson Medium';
    src: url('../fonts/Gibson-Medium.woff2') format('woff2'),
        url('../fonts/Gibson-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gibson Semi Bold';
    src: url('../fonts/Gibson-Semibold.woff2') format('woff2'),
        url('../fonts/Gibson-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiBold';
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bio Rhyme';
    src: url('../fonts/BioRhyme-Regular.woff2') format('woff2'),
        url('../fonts/BioRhyme-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}