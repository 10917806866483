// ========================================================================
// Component: Form
// ========================================================================

// primary form styles
.gform_wrapper {

    .gform_title {
        font-family: $GibsonRegular;
        @include font(30, auto, 38);
        color: $white;
    }
    .partial_entry_warning {
        color: $white !important;
        margin-bottom: 30px !important;
    }
    .gf_progressbar_wrapper {
        display: none;
    }
    .gfield_required {
        font-family: $GibsonRegular;
        @include font(12, auto, 19);
        color: $white !important;
    }
    .ginput_right {
        padding-right: 0 !important;
        padding-left: 0 !important;
        flex: 0 0 100% !important;
    }
    .gform_body {

        ul {
            list-style:none;
            padding:0;

            li {
                margin:0 0 35px 0;
            }
        }
    }

    // labels
    label,
    .gfield_label {
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;
        width: 100%;
        position: relative;
        font-family: $GibsonRegular;
        @include font(21, auto, 28);
        color: $white;
        margin: 0 0 10px 0;
    }

    // inputs
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
        background: transparent;
        border: 1px solid #ffffff;
        padding: 14px 13px !important;
        font-family: $GibsonRegular;
        font-size: 1rem !important;
        line-height: 1.5625rem !important;
        color: $white;
        border-radius: 0;
        width:100% !important;
        -webkit-appearance: none !important;

        &::-webkit-input-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &::-moz-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &:-ms-input-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &:-moz-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &:focus {
            border-radius: 0;
            outline: none;
            box-shadow: 0px 0px 0px 3px #CBD5E1;
        }
    }

    //select
    .custom-a11yselect-container {
        position: relative;
        margin:0 !important;

        select {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:9;
            background:transparent;
            color:transparent;
            border:none;
        }
        .custom-a11yselect-btn {
            background: transparent;
            padding: 14px 13px !important;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            background: transparent;
            border: 1px solid $white;
            width: 100%;
            text-align: left;
            color: $white;
            border-radius: 0;
            min-height: 55px;
            -webkit-appearance: none !important;

            .custom-a11yselect-text {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 50px;
                width: 100%;
            }
            &:focus {
                border-radius: 0;
                outline: none;
                box-shadow: 0px 0px 0px 3px #CBD5E1;
            }
            &:after {
                position: absolute;
                content: "";
                top: 50%;
                right: 22px;
                width: 18px;
                height: 12px;
                //background: url("../images/icon-slider-arrow.svg") 50% no-repeat;
                background-size:100%;
                transform: translate(0, -50%);
                transform-origin: 50% 0;
                z-index: 1;
                transition: $transition;
            }
            &[aria-expanded="true"]:after {
                transform: scaleY(-1) translate(0, -50%);
            }
        }
    }
    .custom-a11yselect-menu {
        display: none;
        position: absolute;
        top: 55px !important;
        left: 0;
        width: 100%;
        background: $white;
        padding: 0;
        z-index: 7;
        list-style: none;
        margin: 0;

        &.opened {
            display: block;
            overflow: auto;
            max-height: 449px;
        }
        .custom-a11yselect-option {
            margin:0;
        }
        button {
            width: 100%;
            background: transparent;
            border: none;
            padding: 12px;
            font-family: $GibsonRegular;
            @include font(16, auto, 25);
            text-align: left;
            color: #183a73;
            border-bottom: 1px solid #183a73;
            cursor: pointer;

            &.active {
                background: #53b2ff;
            }
            &:hover {
                background: #53b2ff;
            }
            &:focus {
                outline: none;
                background: #53b2ff;
            }
            &:focus:active,
            &:focus.active-link {
                background: transparent;
            }
        }
    }

    // textarea
    textarea {
        background: transparent;
        border: 1px solid #ffffff;
        padding: 14px 13px !important;
        font-family: $GibsonRegular;
        font-size: 1rem !important;
        line-height: 1.5625rem !important;
        color: $white;
        border-radius: 0;
        width:100% !important;
        -webkit-appearance: none !important;

        &::-webkit-input-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &::-moz-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &:-ms-input-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &:-moz-placeholder {
            opacity:1;
            font-family: $GibsonRegular;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            color: $white;
        }
        &:focus {
            border-radius: 0;
            outline: none;
            box-shadow: 0px 0px 0px 3px #CBD5E1;
        }
    }

    // file input
    .ginput_container_fileupload {
        position:relative;

        .button {
            font-family: $GibsonMedium;
            font-size: 1rem !important;
            line-height: 1.5625rem !important;
            background:$white;
            color:$blue;
            border:1px solid $white;
            line-height:32px;
            padding:14px 13px;
            width:100%;

            &.focus {
                outline: none !important;
                box-shadow: 0px 0px 0px 3px #CBD5E1 !important;
            }
        }
        &:hover .button {
            background: transparent !important;
            color: $white !important;
        }
        #extensions_message {
            padding-left:20px;
            font-size:11px;
            font-style:italic;
        }
        .validation_message {
            display:none;
        }
        input[type="file"] {
            position: absolute;
            overflow: hidden;
            opacity: 0;
            padding: 0;
            margin: 0;
            top: 0;
            left: 0;
            width:100% !important;
            height:100%;
            z-index: 1;
            cursor: pointer;

            &:hover {
                + label {

                }
            }
            + label {
                color: inherit;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                overflow: hidden;
                text-transform: none;
                display: inline-flex;
                align-items: center;
                transition: all linear 200ms;

                svg {
                    width: 1em;
                    height: 1em;
                    vertical-align: middle;
                }
            }
        }
    }

    .file-name {
        font-family: $GibsonRegular;
        @include font(14, auto, 21);
        color: $white;
        margin:12px 0;

        button {
            font-family: $GibsonRegular;
            @include font(14, auto, 21);
            color: $white;
            margin-left:24px;
            background:transparent;
            border:none;
            cursor:pointer;

            &:hover {
                text-decoration:underline;
            }
            &:focus {
                border-radius: 0;
                outline: none;
                box-shadow: 0px 0px 0px 3px #CBD5E1;
            }
            &:focus:active,
            &:focus.active-link {
                outline:none;
            }
        }
    }

    // checkboxes
    .gfield_checkbox,
    .ginput_container_consent {
        display: flex;
        flex-direction:column;
        position: relative;
        width:100%;

        li {
            position:relative;
            display:inline-block;
            width:auto;
            overflow:visible !important;
        }
        label {
            font-size: 1.125rem !important;
            line-height: 1.6875rem !important;
            padding-left:40px !important;
            line-height:24px !important;
        }
        label:before {
            position: absolute;
            content: '';
            transition: .3s cubic-bezier(.68,-.55,.265,1.55);
            top: 0;
            left: 5px;
            width: 24px;
            height: 24px;
            background:transparent;
            color:$white;
            border-radius:4px;
            background-clip: padding-box;
            border:1px solid $white;
            text-align:center;
        }
        input:checked + label:before {
            content:'✔';
        }
        label.disabled {
            opacity: 0.75;
        }
        input {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            z-index: 1;
            cursor: pointer;
            opacity: 0;
        }
        input:focus + label:before {
            box-shadow: 0px 0px 0px 3px #CBD5E1;
        }
    }

    //radios
    .gfield_radio {
        display: flex;
        flex-direction:column;
        position: relative;
        width:100%;

        li {
            position:relative;
            display:inline-block;
            width:auto;
            overflow:visible !important;
        }
        label {
            font-size: 1.125rem !important;
            line-height: 1.6875rem !important;
            padding-left:40px !important;
            line-height:20px !important;
            margin:0 !important;

            &:before {
                top: 5px;
                left: 9px;
                width:14px;
                height:14px;
                background:$white;
                border-radius: 100%;
                position: absolute;
                content: '';
                transition: .3s cubic-bezier(.68,-.55,.265,1.55);
                opacity:0;
            }
            &:after {
                top: 0;
                left: 4px;
                width:24px;
                height:24px;
                background:transparent;
                border:1px solid $white;
                border-radius: 100%;
                position: absolute;
                content: '';
                transition: .3s cubic-bezier(.68,-.55,.265,1.55);
            }
        }
        input:checked + label:before {
            opacity:1;
        }
        &.disabled {
            opacity: 0.75;
        }
        input {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            z-index: 1;
            cursor: pointer;
            opacity: 0;
        }
        input:focus + label:after {
            box-shadow: 0px 0px 0px 3px #CBD5E1;
        }
    }

    // submit
    input[type="button"],
    input[type="submit"] {
        background: $white;
        border: 1px solid $white;
        font-family: $GibsonMedium;
        font-size: 0.875rem !important;
        line-height: 1.375rem !important;
        color: $blue;
        text-transform: uppercase;
        text-align: center;
        padding: 14px 22px;
        min-width: 200px;
        cursor: pointer;
        -webkit-appearance: none !important;

        &:hover {
            background: transparent !important;
            color: $white !important;
        }
        &:focus {
            outline:2px solid $black;
            outline-offset:2px;
            transition:outline 0s;
        }
        &:focus:not(:focus-visible) {
            outline:none;
        }
    }
}


// gravity forms styles
body img.gform_ajax_spinner {
    margin-left: 20px !important;
}

.gform_wrapper .gfield_error [aria-invalid="true"] {
    background: rgba(255, 170, 170, 0.58) !important;
    border: 2px solid #CF000F !important;
    box-shadow: 0 0 4px 0 #CF000F !important;
}

.gform_wrapper [aria-invalid="true"] + label,
.gform_wrapper label + [aria-invalid="true"],
.gform_wrapper .gfield_error .gfield_repeater_cell label,
.gform_wrapper .gfield_validation_message,
.gform_wrapper .validation_message {
    color: #CF000F!important;
}

.gform_wrapper .gfield_error [aria-invalid="true"],
.gform_wrapper .gfield_error [aria-invalid="true"] + .custom-a11yselect-container .custom-a11yselect-btn {
    background:rgba(255, 255, 255, 0.5) !important;
    border: 2px solid #CF000F !important;
    box-shadow: 0 0 4px 0 #CF000F !important;
}

.gform_wrapper .gfield_error [aria-invalid="true"] + .custom-a11yselect-container .custom-a11yselect-btn .custom-a11yselect-text {
    color: #CF000F!important;
}

.gform_wrapper .gfield_error legend,
.gform_wrapper .gfield_error label {
    color:#fff !important;
}

.gform_validation_errors {
    border-radius: 0;
    font-family: $GibsonSemibold;
    border: 2px solid #CF000F !important;
    background:#CF000F !important;
    color:#fff !important;
    padding:5px !important;
    box-shadow:none !important;
    border-radius:0  !important;
}

.gform_wrapper .validation_error {
    border:none !important;
    margin:0 !important;
    padding:0 !important;
    color:#fff !important;
}

.gform_wrapper .gfield_validation_message,
.gform_wrapper .validation_message {
    font-family: $GibsonSemibold;
    border: 2px solid #CF000F !important;
    background:#CF000F !important;
    color:#fff !important;
    padding:5px !important;
    margin:0 !important;
}

.validation_list {
    display:none;
}

.ginput_complex span {
    margin-bottom: 0 !important;

    label {
        margin-bottom: 0 !important;
    }
}

.gform_confirmation_message {
    color: $white !important;
    margin-top: 220px;
    margin-bottom: 50px;

    a {
        color: $white !important;
    }
    @media only screen and (max-width: 1023px) and (min-width: 1px) {
        margin-top: 0;
        @include font(20, auto, 26);
    }
    @media only screen and (max-width: 767px) and (min-width: 1px) {
        @include font(16, auto, 24);
    }
}


// general form
// .general-form {
//     background:$white !important;

//     h2, p {
//         color:$blue !important;
//     }

//     .gform_wrapper {

//         .gform_title {
//             font-family: $GibsonRegular;
//             @include font(30, auto, 38);
//             color: $blue;
//         }
//         .partial_entry_warning {
//             color: $blue !important;
//             margin-bottom: 30px !important;
//         }
//         .gf_progressbar_wrapper {
//             display: none;
//         }
//         .gfield_required {
//             font-family: $GibsonRegular;
//             @include font(12, auto, 19);
//             color: $blue !important;
//         }
//         .ginput_right {
//             padding-right: 0 !important;
//             padding-left: 0 !important;
//             flex: 0 0 100% !important;
//         }

//         // labels
//         label,
//         .gfield_label {
//             display:flex;
//             flex-direction:row;
//             justify-content:flex-start;
//             align-items:center;
//             width: 100%;
//             position: relative;
//             font-family: $GibsonRegular;
//             @include font(21, auto, 28);
//             color: $blue;
//             margin: 0 0 10px 0;
//         }

//         // inputs
//         input[type="text"],
//         input[type="email"],
//         input[type="tel"] {
//         background: transparent;
//         border: 1px solid $blue;
//         padding: 14px 13px !important;
//         font-family: $GibsonRegular;
//         font-size: 1rem !important;
//         line-height: 1.5625rem !important;
//         color: $blue;
//         border-radius: 0;
//         width:100% !important;
//         -webkit-appearance: none !important;

//             &:focus {
//                 border-radius: 0;
//                 outline: none;
//                 box-shadow: 0px 0px 0px 3px $blue;
//             }
//         }

//         //select
//         .custom-a11yselect-container {
//             position: relative;
//             margin:0 !important;

//             .custom-a11yselect-btn {
//                 background: transparent;
//                 padding: 14px 13px !important;
//                 font-family: $GibsonRegular;
//                 font-size: 1rem !important;
//                 line-height: 1.5625rem !important;
//                 background: transparent;
//                 border: 1px solid $blue;
//                 width: 100%;
//                 text-align: left;
//                 color: $blue;
//                 border-radius: 0;
//                 min-height: 55px;
//                 -webkit-appearance: none !important;

//                 .custom-a11yselect-text {
//                     display: block;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                     padding-right: 50px;
//                     width: 100%;
//                 }
//                 &:focus {
//                     border-radius: 0;
//                     outline: none;
//                     box-shadow: 0px 0px 0px 3px $blue;
//                 }
//                 &:after {
//                     position: absolute;
//                     content: "";
//                     top: 50%;
//                     right: 22px;
//                     width: 18px;
//                     height: 12px;
//                     //background: url("../images/icon-slider-arrow-blue.svg") 50% no-repeat;
//                     background-size:100%;
//                     transform: translate(0, -50%);
//                     transform-origin: 50% 0;
//                     z-index: 1;
//                     transition: $transition;
//                 }
//                 &[aria-expanded="true"]:after {
//                     transform: scaleY(-1) translate(0, -50%);
//                 }
//             }
//         }
//         .custom-a11yselect-menu {
//             display: none;
//             position: absolute;
//             top: 55px !important;
//             left: 0;
//             width: 100%;
//             background: $blue;
//             padding: 0;
//             z-index: 7;
//             list-style: none;
//             margin: 0;

//             &.opened {
//                 display: block;
//                 overflow: auto;
//                 max-height: 449px;
//             }
//             .custom-a11yselect-option {
//                 margin:0;
//             }
//             button {
//                 width: 100%;
//                 background: transparent;
//                 border: none;
//                 padding: 12px;
//                 font-family: $GibsonRegular;
//                 @include font(16, auto, 25);
//                 text-align: left;
//                 color: $white;
//                 border-bottom: 1px solid $white;
//                 cursor: pointer;

//                 &.active {
//                     background: #53b2ff;
//                 }
//                 &:hover {
//                     background: #53b2ff;
//                 }
//                 &:focus {
//                     outline: none;
//                     background: #53b2ff;
//                 }
//                 &:focus:active,
//                 &:focus.active-link {
//                     background: transparent;
//                 }
//             }
//         }

//         // textarea
//         textarea {
//             background: transparent;
//             border: 1px solid $blue;
//             padding: 14px 13px !important;
//             font-family: $GibsonRegular;
//             font-size: 1rem !important;
//             line-height: 1.5625rem !important;
//             color: $blue;
//             border-radius: 0;
//             width:100% !important;
//             -webkit-appearance: none !important;

//             &:focus {
//                 border-radius: 0;
//                 outline: none;
//                 box-shadow: 0px 0px 0px 3px $blue;
//             }
//         }

//         // file input
//         .ginput_container_fileupload {
//             position:relative;

//             .button {
//                 font-family: $GibsonMedium;
//                 font-size: 1rem !important;
//                 line-height: 1.5625rem !important;
//                 background:$blue;
//                 color:$white;
//                 border:1px solid $blue;
//                 line-height:32px;
//                 padding:14px 13px;
//                 width:100%;

//                 &:focus {
//                     outline: none !important;
//                     box-shadow: 0px 0px 0px 3px $blue !important;
//                 }
//             }
//             &:hover .button {
//                 background: transparent !important;
//                 color: $blue !important;
//             }
//             #extensions_message {
//                 padding-left:20px;
//                 font-size:11px;
//                 font-style:italic;
//             }
//             .validation_message {
//                 display:none;
//             }
//             input[type="file"] {
//                 position: absolute;
//                 overflow: hidden;
//                 opacity: 0;
//                 padding: 0;
//                 margin: 0;
//                 top: 0;
//                 left: 0;
//                 width:100% !important;
//                 height:100%;
//                 z-index: 1;
//                 cursor: pointer;

//                 &:hover {
//                     + label {

//                     }
//                 }
//                 + label {
//                     color: inherit;
//                     max-width: 100%;
//                     text-overflow: ellipsis;
//                     white-space: nowrap;
//                     display: inline-block;
//                     overflow: hidden;
//                     text-transform: none;
//                     display: inline-flex;
//                     align-items: center;
//                     transition: all linear 200ms;

//                     svg {
//                         width: 1em;
//                         height: 1em;
//                         vertical-align: middle;
//                     }
//                 }
//             }
//         }

//         .file-name {
//             font-family: $GibsonRegular;
//             @include font(14, auto, 21);
//             color: $blue;
//             margin:12px 0;

//             button {
//                 font-family: $GibsonRegular;
//                 @include font(14, auto, 21);
//                 color: $blue;
//                 margin-left:24px;
//                 background:transparent;
//                 border:none;
//                 cursor:pointer;

//                 &:hover {
//                     text-decoration:underline;
//                 }
//                 &:focus {
//                     border-radius: 0;
//                     outline: none;
//                     box-shadow: 0px 0px 0px 3px $blue;
//                 }
//                 &:focus:active,
//                 &:focus.active-link {
//                     outline:none;
//                 }
//             }
//         }

//         // checkboxes
//         .gfield_checkbox,
//         .ginput_container_consent {
//             display: flex;
//             flex-direction:column;
//             position: relative;
//             width:100%;

//             li {
//                 position:relative;
//                 display:inline-block;
//                 width:auto;
//                 overflow:visible !important;
//             }
//             label {
//                 font-size: 1.125rem !important;
//                 line-height: 1.6875rem !important;
//                 padding-left:40px !important;
//                 line-height:24px !important;
//             }
//             label:before {
//                 position: absolute;
//                 content: '';
//                 transition: .3s cubic-bezier(.68,-.55,.265,1.55);
//                 top: 0;
//                 left: 5px;
//                 width: 24px;
//                 height: 24px;
//                 background:transparent;
//                 color:$blue;
//                 border-radius:4px;
//                 background-clip: padding-box;
//                 border:1px solid $blue;
//                 text-align:center;
//             }
//             input:checked + label:before {
//                 content:'✔';
//             }
//             label.disabled {
//                 opacity: 0.75;
//             }
//             input {
//                 position: absolute;
//                 display: block;
//                 top: 0;
//                 left: 0;
//                 width:100%;
//                 height:100%;
//                 z-index: 1;
//                 cursor: pointer;
//                 opacity: 0;
//             }
//             input:focus + label:before {
//                 box-shadow: 0px 0px 0px 3px $blue;
//             }
//         }

//         //radios
//         .gfield_radio {
//             display: flex;
//             flex-direction:column;
//             position: relative;
//             width:100%;

//             li {
//                 position:relative;
//                 display:inline-block;
//                 width:auto;
//                 overflow:visible !important;
//             }
//             label {
//                 font-size: 1.125rem !important;
//                 line-height: 1.6875rem !important;
//                 padding-left:40px !important;
//                 line-height:20px !important;
//                 margin:0 !important;

//                 &:before {
//                     top: 5px;
//                     left: 9px;
//                     width:14px;
//                     height:14px;
//                     background:$blue;
//                     border-radius: 100%;
//                     position: absolute;
//                     content: '';
//                     transition: .3s cubic-bezier(.68,-.55,.265,1.55);
//                     opacity:0;
//                 }
//                 &:after {
//                     top: 0;
//                     left: 4px;
//                     width:24px;
//                     height:24px;
//                     background:transparent;
//                     border:1px solid $blue;
//                     border-radius: 100%;
//                     position: absolute;
//                     content: '';
//                     transition: .3s cubic-bezier(.68,-.55,.265,1.55);
//                 }
//             }
//             input:checked + label:before {
//                 opacity:1;
//             }
//             &.disabled {
//                 opacity: 0.75;
//             }
//             input {
//                 position: absolute;
//                 display: block;
//                 top: 0;
//                 left: 0;
//                 width:100%;
//                 height:100%;
//                 z-index: 1;
//                 cursor: pointer;
//                 opacity: 0;
//             }
//             input:focus + label:after {
//                 box-shadow: 0px 0px 0px 3px $blue;
//             }
//         }

//         // submit
//         input[type="button"],
//         input[type="submit"] {
//             background: $blue;
//             border: 1px solid $blue;
//             font-family: $GibsonMedium;
//             @include font(14, auto, 22);
//             color: $white;
//             text-transform: uppercase;
//             text-align: center;
//             padding: 14px 22px;
//             min-width: 200px;
//             cursor: pointer;
//             -webkit-appearance: none !important;

//             &:hover {
//                 background: $white !important;
//                 color: $blue !important;
//             }
//             &:focus {
//                 outline: none;
//                 box-shadow: 0px 0px 0px 3px $blue;
//             }
//         }
//     }
// }
