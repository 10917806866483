//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================
// Component: Variables
// ========================================================================

$base-font-size: 16;

// Fonts
$GibsonRegular: 'Gibson Regular';
$GibsonBook: 'Gibson Book';
$GibsonMedium: 'Gibson Medium';
$GibsonSemibold: 'Gibson Semi Bold';
$OpenSansBold: 'Open Sans Bold';
$OpenSansSemiBold: 'Open Sans SemiBold';
$BioRhyme: 'Bio Rhyme';

// Colors
$black: #090909;
$white: #F2F1EC;
$blue: #0055B8;
$yellow: #CDDB00;
$off_white: #E5E5E5;

// Layout
$row_margin: -12px;
$col_padding: 12px;
$row_margin_tablet: -12;
$col_padding_tablet: 12;

// Max Width
$max-width: 1680px;

// Transition
$transition:.4s;