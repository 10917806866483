//
// Name:            Footer
//
// Description:     Contains all footer styles
//
// ========================================================================
// Component: Footer
// =========================================================================

footer {
    display:block;
    position:relative;
    padding:72px 120px 30px 120px;
    background:#000000;

    @media only screen and (max-width: 1180px) and (min-width: 1px) {
        padding:35px 40px;
    }
    @media only screen and (max-width: 767px) and (min-width: 1px) {
        padding:35px 32px;
    }
    .footer-top {
        display:flex;
        flex-direction:row;

        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            flex-direction:column;
            align-items:center;
        }
        .footer-logo {
            position:relative;
            width:288px;
            min-width:288px;
            height:47px;
            margin-top:10px;
            margin-right:78px;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                margin:0 auto;
            }
            span {
                position:absolute;
                top:0;
                left:0;
                text-indent:-9999px;
            }
            &:focus {
                outline: 1px solid $white;
            }
            &:focus:active,
            &:focus.active-link {
                box-shadow:none;
                outline:none;
            }
        }
        .footer-row {
            display:flex;
            flex-direction:row;
            width:100%;
        }
        @media only screen and (max-width: 1024px) and (min-width: 1px) {
            .footer-row {
                display:flex;
                flex-direction:column;
            }
        }
        .footer-left {
            display:inline-flex;
            flex-direction:column;
            flex:1;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                flex-basis:100%;
                max-width:100%;
            }
            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                align-items:center;
            }
            .colleges {
                font-family: $BioRhyme;
                @include font(30, auto, 46);
                color: $white;
                margin:0 0 45px 0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    @include font(24, auto, 41);
                    margin:0 0 16px 0;
                }
                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    margin:32px 0 16px 0;
                }
            }
            ul {
                display:flex;
                flex-direction:column;
                justify-content:flex-start;
                list-style:none;
                padding:0;
                margin:0;

                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    margin:20px 0 0 0;
                }
                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    flex-direction:column;
                    align-items:center;
                    width:100%;
                }
                li {
                    display:inline-flex;
                    margin-bottom:10px;

                    a {
                        font-family: $GibsonRegular;
                        @include font(21, auto, 24);
                        color: $white;
                        border: 1px solid transparent;
                        text-transform:uppercase;
                        text-decoration:none;

                        @media only screen and (max-width: 1024px) and (min-width: 1px) {
                            text-align:center;
                        }
                        &:hover {
                            color:$white;
                            text-decoration:underline;
                        }
                        &:focus {
                            outline:none;
                            border: 1px solid $white;
                        }
                        &:focus:active,
                        &:focus.active-link {
                            outline:none;
                            box-shadow:none;
                            border: 1px solid transparent;
                        }
                    }
                }
            }
        }
        .footer-right {
            display:inline-flex;
            flex-direction:column;
            flex:1;

            // @media only screen and (max-width: 1024px) and (min-width: 1px) {
            //     min-width:calc(100% + 203px);
            //     flex-basis:calc(100% + 203px);
            //     max-width:calc(100% + 203px);
            //     margin-top:30px;
            //     margin-left:-203px;
            //     flex-direction:row;
            //     justify-content:space-between;
            //     align-items:center;
            // }
            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                min-width:100%;
                flex-basis:100%;
                max-width:100%;
                margin-top:30px;
                margin-left:0;
                flex-direction:column;
            }
            .share {
                margin:0 0 30px 0;

                // @media only screen and (max-width: 1024px) and (min-width: 1px) {
                //     margin:40px 100px 0 0;
                // }
                @media only screen and (max-width: 1024px) and (min-width: 1px) {
                    margin:0 auto 48px auto;
                }
                p {
                    font-family: $BioRhyme;
                    @include font(30, auto, 46);
                    color: $white;
                    margin:0 0 10px 0;

                    @media only screen and (max-width: 1024px) and (min-width: 1px) {
                        @include font(16, auto, 32);
                    }
                    @media only screen and (max-width: 767px) and (min-width: 1px) {
                        text-align:center;
                    }
                }
                ul {
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    list-style:none;
                    padding:0;
                    margin:0 -8.5px;

                    li {
                        padding:0 8.5px;

                        a {
                            display:block;
                            position:relative;
                            width:38px;
                            height:38px;
                            padding:2px;
                            border:1px solid transparent;
                            transition:$transition;

                            img,
                            svg {
                                position:absolute;
                                top:50%;
                                left:50%;
                                height:100%;
                                transform:translate(-50%, -50%);
                            }
                            span {
                                position:absolute;
                                top:0;
                                left:0;
                                text-indent:-9999px;
                            }
                            &:hover {
                                transform:scale(1.2);

                                svg path {
                                    
                                }
                            }
                            &:focus {
                                outline:none;
                                border: 1px solid $white;
                            }
                            &:focus:active,
                            &:focus.active-link {
                                outline:none;
                                box-shadow:none;
                                border:none;
                            }
                        }
                    }
                }
            }
            .signup {

                p {
                    font-family: $BioRhyme;
                    @include font(16, auto, 24);
                    color: $white;
                    margin:0;
                }
                form {
                    position:relative;
                    margin:15px 0;

                    label {
                        font-family: $GibsonRegular;
                        @include font(18, auto, 27);
                        color:$white;
                        text-transform:none;
                        letter-spacing:0;
                    }
                    input {
                        width:100%;
                        position:relative;
                        background:transparent;
                        border:2px solid $white;
                        padding:17px 22px 17px 22px;
                        color:$white;
                        z-index:5;
                        font-family: $GibsonRegular;
                        @include font(16, auto, 16);
                        border-radius:0;
                        margin:0;
                        -webkit-appearance:none !important;

                        &:focus {
                            outline:none;
                            box-shadow: 0 0 4px 0 $white;
                        }
                    }

                    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
                    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }
                    input[type="search"]::-webkit-search-decoration,
                    input[type="search"]::-webkit-search-cancel-button,
                    input[type="search"]::-webkit-search-results-button,
                    input[type="search"]::-webkit-search-results-decoration { display: none; }

                    button {
                        position:absolute;
                        top:29px;
                        right:2px;
                        width:50px;
                        height:50px;
                        padding:0;
                        border:none;
                        background:url('../images/icon-arrow.svg') 50% no-repeat;
                        text-indent:-9999px;                
                        z-index:8;
                        cursor:pointer;

                        &:hover {
                            
                        }
                        &:focus {
                            outline: 1px solid $white;
                            outline-offset:0;
                        }
                        &:focus:active,
                        &:focus.active-link {
                            box-shadow:none;
                            outline:none;
                        }
                    }
                }
                .note {
                    font-family: $GibsonRegular;
                    @include font(16, auto, 25);
                    color: $white;
                    margin:0;
                }
            }
        }
    }
    .footer-bottom {
        margin:65px 0 35px 0;

        nav ul {
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content: center;
            margin-left:-20px;
            margin-right:-20px;
            padding:0;

            @media only screen and (max-width: 1024px) and (min-width: 1px) {
                flex-direction:column;
            }
            li {
                display:inline-flex;
                padding:0 20px;

                @media only screen and (max-width: 767px) and (min-width: 1px) {
                    margin-bottom:15px;
                }
                a {
                    font-family: $GibsonRegular;
                    @include font(16, auto, 24);
                    color: $white;
                    text-decoration:none;
                    border: 1px solid transparent;

                    &:hover {
                        color:$white;
                        text-decoration:underline;
                    }
                    &:focus {
                        outline:none;
                        border: 1px solid $white;
                    }
                    &:focus:active,
                    &:focus.active-link {
                        outline:none;
                        box-shadow:none;
                        border:none;
                    }
                }
            }
        }
    } 
}
