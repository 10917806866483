//
// Name:            Layout
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//
// ========================================================================
// Component: Base
// ========================================================================

main {
	position: relative;
	padding: 0;
}

.max-width {
	position:relative;
	margin: 0 auto;
	width:100%;
	max-width:1620px;
}

// Flex Layout

// row
.row {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	flex: 0 1 auto;
	margin: 0 $row_margin 0 $row_margin;
	box-sizing: border-box;

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		margin: 0 $row_margin 0 $row_margin;
	}
	& + .row {
		margin: 40px $row_margin 0 $row_margin;

		@media only screen and (max-width: 1024px) and (min-width: 1px) {
			margin: 40px $row_margin 0 $row_margin;
		}
	}
	&.start {
		justify-content:flex-start;	
	}
	&.end {
		justify-content:flex-end;	
	}
	&.center {
		justify-content:center;
		text-align:center;
	}
	&.between {
		justify-content:space-between;	
	}
	&.around {
		justify-content:space-around;	
	}
	&.top {
		align-items:flex-start;
	}
	&.middle {
		align-items:center;
	}
	&.bottom {
		align-items:flex-end;
	}
}

// columns
[class*="col-"] {
	display: inline-flex;
    flex-direction: column;
	flex: 0 0 auto;
	padding: 0 $col_padding 0 $col_padding;
	box-sizing: border-box;

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		padding: 0 $col_padding 0 $col_padding;
	}
}

.col-12 {
	flex-basis:100%;
	max-width:100%;
}

.col-11 {
	flex-basis:91.66666667%;
	max-width:91.66666667%;
}

.col-10 {
	flex-basis:83.33333333%;
	max-width:83.33333333%;
}

.col-9 {
	flex-basis:75%;
	max-width:75%;
}

.col-8 {
	flex-basis:66.66666667%;
	max-width:66.66666667%;
}

.col-7 {
	flex-basis:58.33333333%;
	max-width:58.33333333%;
}

.col-6 {
	flex-basis:50%;
	max-width:50%;
}

.col-5 {
	flex-basis:41.66666667%;
	max-width:41.66666667%;
}

.col-4 {
	flex-basis:33.33333333%;
	max-width:33.33333333%;
}

.col-3 {
	flex-basis:25%;
	max-width:25%;
}

.col-2 {
	flex-basis:16.66666667%;
	max-width:16.66666667%;
}

.col-1 {
	flex-basis:8.33333333%;
	max-width:8.33333333%;
}


// Grid Layout
.split-grid {
	display:grid;
    position:relative;
    grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: auto;
  	grid-gap: 0;

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		display:flex;
		flex-direction:column;
	}
}

.tri-grid {
	display:grid;
    position:relative;
    grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-template-rows: auto;
  	grid-gap: 0;

	@media only screen and (max-width: 767px) and (min-width: 1px) {
		grid-template-columns: auto;
		grid-template-rows: repeat(3, minmax(0, 1fr));
	}
}

.quad-grid {
	display:grid;
    position:relative;
    grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-template-rows: auto;
  	grid-gap: 0;

	@media only screen and (max-width: 1024px) and (min-width: 1px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: repeat(2, minmax(0, 1fr));
	}
	@media only screen and (max-width: 767px) and (min-width: 1px) {
		grid-template-columns: auto;
		grid-template-rows: repeat(4, minmax(0, 1fr));
	}
}