section {
    position:relative;
    padding:50px 120px;

    @media only screen and (max-width: 1024px) and (min-width: 1px) {
        padding:50px 70px;
    }
    @media only screen and (max-width: 767px) and (min-width: 1px) {
        padding:33px;
    }
    &.gray-bg {
        background:$off_white;
    }
    &.blue-bg {
        background:$blue;

        h2, h3, h4, p, li, label, a:not(.btn) {
            color:$white;
        }
    }
    &.yellow-bg {
        background:$yellow;
    }
    &.black-bg {
        background:$black;

        h2, h3, h4, p, li, label, a:not(.btn) {
            color:$white;
        }
    }
}
