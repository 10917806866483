// ========================================================================
// Common: Modal
// ========================================================================


.hidden, .invisible { display: none; }

/* needed for old browsers */
dialog {
    display: block;
    border: 0;
}

/* removes scroll when modal is opened */
.no-scroll {
    overflow: hidden;
}

/* overlay covers everything */
.simple-modal-overlay,
.simple-animated-modal-overlay,
.simple-animatedtransform-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($blue, 0.9);
    z-index: 666;
    cursor: pointer;
}
.simple-modal-overlay[data-background-click="disabled"],
.simple-animated-modal-overlay[data-background-click="disabled"],
.simple-animatedtransform-modal-overlay[data-background-click="disabled"],
.simple-left-modal-overlay[data-background-click="disabled"] {
    cursor: auto;
}
.simple-animated-modal-overlay,
.simple-animatedtransform-modal-overlay {
    animation: fadewhite ease .5s 1 normal;
}

@keyframes fadewhite {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* modal */
.simple-modal,
.simple-animated-modal,
.simple-animatedtransform-modal {
    position: fixed;
    left: 15%;
    width: 70%;
    top: 50%;
    max-height: 90vh;
    /*
    If you have problems with vh units 
    top: 5%;
    max-height: 90%;
    */
    border: none; 
    background: transparent;
    z-index: 667;
    padding: 110px 0;
    right: auto;
    overflow: hidden;
    transform:translate(0, -50%);
}
.simple-modal-close,
.simple-animated-modal-close,
.simple-animatedtransform-modal-close {
    position:absolute;
    top:5px;
    right:5px;
    width:24px;
    height:24px;
    background: url('../images/icon-close-white.svg') 50% no-repeat;
    border: 0;
    padding:0;
    cursor: pointer;
    z-index:9;
    transition:$transition;

    span {
        position:absolute;
        top:0;
        left:0;
        text-indent:-9999px;
    }
    &:hover {
        outline:2px solid $white;
        outline-offset:2px;
    }
    &:focus {
        outline:2px solid $white;
        outline-offset:2px;
        transition:0s;
    }
}
.simple-modal-close:focus,
.simple-modal-close:hover,
.simple-modal-close:active {
    outline: 1px dotted #fff;  
}
.simple-modal-close:hover,
.simple-modal-close:active {
    
}

.simple-animated-modal {
    -webkit-animation: apparition ease .5s 1 normal ;
            animation: apparition ease .5s 1 normal ;
}
.simple-animatedtransform-modal {
    -webkit-animation: pop cubic-bezier(0.61,-0.19, 0.7,-0.11) .5s 1 normal ;
            animation: pop cubic-bezier(0.61,-0.19, 0.7,-0.11) .5s 1 normal ;
}

@-webkit-keyframes apparition {
    0% {
        opacity: 0;
        max-height: 0;
        width: 0;
        left: 50%;
    }
    100% {
        opacity: 1;
        max-height: 100%;
        width: 70%;
        left: 15%;
    }
}

@keyframes apparition {
    0% {
        opacity: 0;
        max-height: 0;
        width: 0;
        left: 50%;
    }
    100% {
        opacity: 1;
        max-height: 100%;
        width: 70%;
        left: 15%;
    }
}

@-webkit-keyframes pop {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            -webkit-transform:translate(0, -50%) scale(0);
                    transform:translate(0, -50%) scale(0);
    }
    100% {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transform:translate(0, -50%) scale(1);
                transform:translate(0, -50%) scale(1);
        max-height: 100%;
        width: 70%;
        left: 15%;
    }
}

@keyframes pop {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            -webkit-transform:translate(0, -50%) scale(0);
                    transform:translate(0, -50%) scale(0);
    }
    100% {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transform:translate(0, -50%) scale(1);
                transform:translate(0, -50%) scale(1);
        max-height: 100%;
        width: 70%;
        left: 15%;
    }
}

.simple-animatedtransform-modal__content {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    max-width:860px;

    .video-wrapper {
        display:flex;
        flex-direction:column;
        position:relative;
        width:100%;
        height:0;
        padding-top:56.25%;
    }
    iframe {
        position:absolute;
        top:0;
        left:50%;
        width:100%;
        height:100%;
        transform:translate(-50%, 0);
    }
}

/* it can be easily adapted in media-queries for tablets/mobile */

/* for this example: tablets */
@media (max-width: 55.625em) {

  .simple-modal,
  .simple-animated-modal {
    left: 5%;
    top: 5%;
    height: 90%;
    width: 90%;
  }
  
}

/* for this example: mobile */
@media (max-width: 44.375em) {

  .simple-modal,
  .simple-animated-modal {
    left: 1%;
    top: 1%;
    width: 98%;
    height: 98%;
  }

}