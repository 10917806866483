//
// Name:            Buttons
//
// Description:     Contains all button styles
//
// ========================================================================
// Component
// ========================================================================

.btn {
    display:inline-flex;
    justify-content:center;
	padding:12px 60px;
    font-family: $OpenSansBold;
    @include font(14, auto, 24);
    letter-spacing: 0.15em;
    text-transform: uppercase;
    border:2px solid transparent;
    transition:$transition;

    @media only screen and (max-width: 767px) and (min-width: 1px) {
        padding:12px 30px;
    }
    &-blue {
        background:$blue;
        color:$white;
        border:2px solid $blue;

        &:hover {
            background:$yellow;
            color:$black;
            border:2px solid $yellow;
        }
        &:active {
            color:$white;
            background:#2578D9;
            border:2px solid #2578D9;
        }
        &:focus {
            outline:2px solid $black;
            outline-offset:2px;
            transition:outline 0s;
        }
        &:focus:not(:focus-visible) {
            outline:none;
        }
    }
    &-yellow {
        background:$yellow;
        color:$black;
        border:2px solid $yellow;

        &:hover {
            background:$blue;
            color:$white;
            border:2px solid $blue;
        }
        &:active {
            color:$black;
            background:#BFC65C;
            border:2px solid #BFC65C;
        }
        &:focus {
            outline:2px solid $blue;
            outline-offset:2px;
            transition:outline 0s;
        }
        &:focus:not(:focus-visible) {
            outline:none;
        }
    }
    &-black {
        background:$black;
        color:$white;
        border:2px solid $black;

        &:hover {
            background:$white;
            color:$black;
            border:2px solid $white;
        }
        &:active {
            color:$white;
            background:#3F3F3F;
            border:2px solid #3F3F3F;
        }
        &:focus {
            outline:2px solid $yellow;
            outline-offset:2px;
            transition:outline 0s;
        }
        &:focus:not(:focus-visible) {
            outline:none;
        }
    }
    &-knockout {
        background:transparent;
        color:$black;
        border:2px solid $black;

        &:hover {
            background:$black;
            color:$white;
        }
        &:active {
            color:$white;
            background:#3F3F3F;
        }
        &:focus {
            outline:2px solid $yellow;
            outline-offset:2px;
            transition:outline 0s;
        }
        &:focus:not(:focus-visible) {
            outline:none;
        }
    }
}